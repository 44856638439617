import React from 'react';

const Group16 = (props)=> {
  return (
    <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="7.37805" height="5.90244" rx="1" fill="#414E62"/>
<rect x="9.52344" width="7.37805" height="5.90244" rx="1" fill="#414E62"/>
<rect x="19.0488" width="7.37805" height="5.90244" rx="1" fill="#414E62"/>
<rect y="8.04883" width="7.37805" height="5.90244" rx="1" fill="#414E62"/>
<rect x="9.52344" y="8.04883" width="7.37805" height="5.90244" rx="1" fill="#414E62"/>
<rect x="19.0488" y="8.04883" width="7.37805" height="5.90244" rx="1" fill="#414E62"/>
<rect y="16.0977" width="7.37805" height="5.90244" rx="1" fill="#414E62"/>
<rect x="9.52344" y="16.0977" width="7.37805" height="5.90244" rx="1" fill="#414E62"/>
<rect x="19.0488" y="16.0977" width="7.37805" height="5.90244" rx="1" fill="#414E62"/>
</svg>

  );
};

export default Group16;
