import React from "react";

import Description from "./Description";
import Main from "./Main";

const Live = () => {
  return (
    <>
      <Description />
      <Main />
    </>
  );
};

export default Live;
