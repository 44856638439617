import styled from "styled-components";

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ headerState }) => (headerState ? "100px" : "70px")};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
  background-color: white;
`;

export default HeaderWrapper;
