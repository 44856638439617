const data = {
  recentSearch: [
    {
      camera: "All Cameras",
      date: "Last 7 days",
      time: "08AM~05PM(Everyday)",
    },
    {
      camera: "Camera2",
      date: "Yesterday",
      time: "10AM~10PM",
    },
    {
      camera: "Main Entrance All Cameras",
      date: "22.04.2023~24.04.2023",
      time: "08AM~05PM(Everyday)",
    },
  ],
};

export default data;
