import React from "react";
// import VideoEditingTimeline from "video-editing-timeline-react";

const config = {
  mode: "stander",
  canvasWidth: 3000,
  canvasHeight: 50,
  minimumScale: 10,
  minimumScaleTime: 1,
};

const Events = () => {
  return (
    <div>
      This is a events page
      {/* <VideoEditingTimeline config={config} /> */}
    </div>
  );
};

export default Events;
