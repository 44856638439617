import { Link } from "react-router-dom";
import styled from "styled-components";

export default styled(Link)`
  display: flex;
  align-items: "center";
  margin: 0 15px;
  cursor: pointer;
  font-size: 16px;
  color: ${({ isActive }) => (isActive ? "#FF6600" : "#414E62")};
  text-align: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none !important;
  width: 100px;
  span {
    color: ${({ isActive }) => (isActive ? "#FF6600 !important" : "#414E62")};
    font-family: "Lato";
  }
  &:hover {
    color: #ff6600;
    svg {
      stroke: #ff6600;
      fill: #ff6600;
    }
    span {
      color: #ff6600 !important;
    }
  }
  svg {
    stroke: ${({ isActive }) => (isActive ? "#FF6600" : "#414E62")};
    fill: ${({ isActive }) => (isActive ? "#FF6600" : "#414E62")};
  }
`;
