

import React from 'react';

const Warning = (props)=> {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.41699 12.103C3.27866 12.103 0.729492 9.55386 0.729492 6.41553C0.729492 3.27719 3.27866 0.728027 6.41699 0.728027C9.55533 0.728027 12.1045 3.27719 12.1045 6.41553C12.1045 9.55386 9.55533 12.103 6.41699 12.103ZM6.41699 1.60303C3.76283 1.60303 1.60449 3.76136 1.60449 6.41553C1.60449 9.06969 3.76283 11.228 6.41699 11.228C9.07116 11.228 11.2295 9.06969 11.2295 6.41553C11.2295 3.76136 9.07116 1.60303 6.41699 1.60303Z" fill="#F2F2F2"/>
      <path d="M11.7603 13.2942C11.7136 13.2942 11.667 13.2883 11.6261 13.2825C11.352 13.2475 10.8561 13.0608 10.5761 12.2267C10.4303 11.7892 10.4828 11.3517 10.722 11.0192C10.9611 10.6867 11.3636 10.5 11.8245 10.5C12.4195 10.5 12.8861 10.7275 13.0961 11.13C13.3061 11.5325 13.2478 12.0458 12.9153 12.5417C12.5011 13.1658 12.052 13.2942 11.7603 13.2942ZM11.4103 11.9525C11.5095 12.2558 11.6495 12.4075 11.7428 12.4192C11.8361 12.4308 12.0111 12.32 12.192 12.0575C12.3611 11.8067 12.3728 11.6258 12.332 11.5442C12.2911 11.4625 12.1278 11.375 11.8245 11.375C11.6436 11.375 11.5095 11.4333 11.4336 11.5325C11.3636 11.6317 11.352 11.7833 11.4103 11.9525Z" fill="#F2F2F2"/>
    </svg>
  );
};

export default Warning;
