import React from "react";
import styled from "styled-components";

import { Avatar } from "antd";
import DownArrow from "./Icons/DownArrow";

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AvatarI = () => {
  return (
    <AvatarWrapper>
      <Avatar src="./Icons/Avatar.png" />
      <DownArrow />
    </AvatarWrapper>
  );
};

export default AvatarI;
