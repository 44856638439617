const data = {
  camera: [
    {
      label: "All Cameras",
      value: "All Cameras",
      children: [
        {
          label: "Parking Lot",
          value: "Parking Lot",
          children: [
            { label: "Camera 1", value: "Camera 1" },
            { label: "Camera 2", value: "Camera 2" },
            { label: "Camera 3", value: "Camera 3" },
            { label: "Camera 4", value: "Camera 4" },
          ],
        },
        { label: "Main Entrance", value: "Main Entrance", children: [] },
      ],
    },
  ],
  sortKey: [
    {
      label: "Location",
      key: "Location",
    },
    {
      label: "Time",
      key: "Time",
    },
    {
      label: "Person",
      key: "Person",
    },
  ],
  videoImage: [
    {
      cameraId: "camera 1",
      roomId: "Outside Entrance",
      content: "",
    },
    {
      cameraId: "camera 2",
      roomId: "In Entrance",
      content: "",
    },
    {
      cameraId: "camera 3",
      roomId: "Upside Entrance",
      content: "",
    },
    {
      cameraId: "camera 4",
      roomId: "Downside Entrance",
      content: "",
    },
    {
      cameraId: "camera 1",
      roomId: "Outside Entrance",
      content: "",
    },
    {
      cameraId: "camera 1",
      roomId: "Outside Entrance",
      content: "",
    },
    {
      cameraId: "camera 1",
      roomId: "Outside Entrance",
      content: "",
    },
    {
      cameraId: "camera 1",
      roomId: "Outside Entrance",
      content: "",
    },
  ],
};

export default data;
