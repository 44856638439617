import React from "react";
import styled from "styled-components";
import {
  AiOutlineEnvironment,
  AiOutlineCalendar,
  AiOutlineClockCircle,
  AiOutlineDelete,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { Button } from "antd";

import data from "./Data";

const RecentSearch = styled.div`
  padding: 10px;
  width: 50%;
  border-right: 1px solid #e0e0e0;
`;

const SuggestionQuery = styled.div`
  padding-left: 35px;
  padding-top: 10px;
`;

const Title = styled.div`
  margin-bottom: 35px;
  color: #97a1af;
  font-weight: 500;
  font-size: 24px;
`;

const RecentDescription = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #344051;
`;

const SuggestionDescription = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: #344051;
`;

const DeleteSearchHistory = styled(Button)`
  margin-top: 50px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #475569;
  padding: 16px;
`;

const Footer = () => {
  return (
    <div style={{ display: "flex", borderTop: "1px solid #E0E0E0" }}>
      <RecentSearch>
        <Title>Recent Searches</Title>
        <RecentDescription>
          {data.recentSearch.map((data, id) => (
            <div
              key={`${id} recent description`}
              style={{
                padding: "4px 0",
                borderBottom: "1px solid #F2F2F2",
                margin: "12.5px 0",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <AiOutlineEnvironment />
                &nbsp;
                {data.camera}&nbsp;&nbsp;
                <AiOutlineCalendar />
                &nbsp;
                {data.date}&nbsp;&nbsp;
                <AiOutlineClockCircle />
                &nbsp;
                {data.time}&nbsp;&nbsp;
              </div>
              <AiOutlineCloseCircle />
            </div>
          ))}
        </RecentDescription>
        <DeleteSearchHistory type="text">
          <AiOutlineDelete size="20" />
          &nbsp;&nbsp; DELETE RECENT HISTORY
        </DeleteSearchHistory>
      </RecentSearch>
      <SuggestionQuery>
        <Title>Suggestion Queries</Title>
        <SuggestionDescription>
          <div style={{ marginBottom: 17 }}>
            Show me videos from the busiest hour yesterday
          </div>
          <div>Show me videos from the busiest hour last week, everyday</div>
        </SuggestionDescription>
      </SuggestionQuery>
    </div>
  );
};

export default Footer;
