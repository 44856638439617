import React from "react";
import styled from "styled-components";

import WarnIcon from "./Icons/Warning";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  color: #97a1af;
`;
const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  font-weight: 700;
  color: #202b37;
`;
const Announce = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  color: #414e62;
  font-weight: 500;
  font-size: 16px;
`;

const Description = () => {
  const now = new Date();
  const istOptions = {
    timeZone: "Asia/Kolkata",
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const istTime = now.toLocaleString("en-US", istOptions);
  return (
    <>
      <Header>
        <Title>Live View</Title>
        <Announce>
          <WarnIcon />
          <div>
            <b>Note:</b> The Live View Cameras have 4-5 seconds lag.
          </div>
        </Announce>
      </Header>
      <Footer>{istTime}(IST)</Footer>
    </>
  );
};

export default Description;
