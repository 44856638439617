

import React from 'react';

const Warning = (props)=> {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.66634 7.59961H6.33301C6.05967 7.59961 5.83301 7.37294 5.83301 7.09961C5.83301 6.82628 6.05967 6.59961 6.33301 6.59961H9.66634C9.93967 6.59961 10.1663 6.82628 10.1663 7.09961C10.1663 7.37294 9.93967 7.59961 9.66634 7.59961Z" fill="white"/>
      <path d="M3.28703 15.1663C3.00703 15.1663 2.74703 15.0997 2.5137 14.9663C2.00036 14.6663 1.70703 14.0597 1.70703 13.3063V3.90634C1.70703 2.21301 3.08703 0.833008 4.78036 0.833008H11.2137C12.907 0.833008 14.287 2.21301 14.287 3.90634V13.2997C14.287 14.053 13.9937 14.6597 13.4804 14.9597C12.967 15.2597 12.2937 15.2263 11.6337 14.8597L8.38036 13.053C8.18703 12.9463 7.80703 12.9463 7.6137 13.053L4.36036 14.8597C4.00036 15.0597 3.6337 15.1663 3.28703 15.1663ZM4.78703 1.83301C3.64703 1.83301 2.7137 2.76634 2.7137 3.90634V13.2997C2.7137 13.693 2.82703 13.9863 3.02703 14.0997C3.22703 14.2197 3.54036 14.1797 3.88036 13.9863L7.1337 12.1797C7.62703 11.9063 8.3737 11.9063 8.86703 12.1797L12.1204 13.9863C12.4604 14.1797 12.7737 14.2197 12.9737 14.0997C13.1737 13.9797 13.287 13.6863 13.287 13.2997V3.90634C13.287 2.76634 12.3537 1.83301 11.2137 1.83301H4.78703Z" fill="white"/>
    </svg>
  );
};

export default Warning;
