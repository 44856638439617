import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import HeaderWrapper from "./HeaderWrapper";
import HeaderLink from "./HeaderLink";
import UserWrapper from "./UserWrapper";
import Navbar from "./Navbar";
import Logo from "./Logo";
import Avatar from "./Avatar";
import Notifi from "./Notifi";

import LiveIcon from "./Icons/Live";
import SearchIcon from "./Icons/Search";
import EventsIcon from "./Icons/Events";
import DevicesIcon from "./Icons/Devices";
import InsightsIcon from "./Icons/Insights";

const Header = () => {
  const pathname = useLocation().pathname;
  const [headerState, setHeader] = useState(false);
  return (
    <HeaderWrapper
      onMouseEnter={() => {
        setHeader(true);
      }}
      onMouseLeave={() => {
        setHeader(false);
      }}
      headerState={headerState}
    >
      <Navbar headerState={headerState}>
        <Logo headerState={headerState} />
        <HeaderLink to="/live" isActive={pathname === "/live"}>
          <LiveIcon />
          <span>Live</span>
        </HeaderLink>
        <HeaderLink to="/search" isActive={pathname === "/search"}>
          <SearchIcon />
          <span>Search</span>
        </HeaderLink>
        <HeaderLink to="/events" isActive={pathname === "/events"}>
          <EventsIcon />
          <span>Events</span>
        </HeaderLink>
        <HeaderLink to="/devices" isActive={pathname === "/devices"}>
          <DevicesIcon />
          <span>Devices</span>
        </HeaderLink>
        <HeaderLink to="/insights" isActive={pathname === "/insights"}>
          <InsightsIcon />
          <span>Insights</span>
        </HeaderLink>
      </Navbar>
      <UserWrapper>
        <Notifi />
        <Avatar />
      </UserWrapper>
    </HeaderWrapper>
  );
};

export default Header;
