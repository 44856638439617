
import React from 'react';

const Map = (props)=> {
  return (
    <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.5623 20.302C18.0248 20.302 17.4873 20.2083 17.0498 20.0312L10.4873 17.2916C10.1123 17.1353 9.37481 17.1458 9.01231 17.3228L6.0623 18.7291C4.7873 19.3333 3.4748 19.4166 2.4873 18.927C1.4873 18.4478 0.924805 17.4687 0.924805 16.2395V6.1145C0.924805 5.16658 1.6748 4.09367 2.6623 3.62492L8.07481 1.04158C8.9873 0.604085 10.3748 0.572835 11.3123 0.968668L17.8748 3.70825C18.2498 3.8645 18.9748 3.84367 19.3498 3.677L22.2873 2.28117C23.5623 1.677 24.8748 1.59367 25.8623 2.08325C26.8623 2.56242 27.4248 3.54158 27.4248 4.77075V14.9062C27.4248 15.8541 26.6748 16.927 25.6873 17.3958L20.2748 19.9791C19.7998 20.1874 19.1748 20.302 18.5623 20.302ZM9.7998 15.6249C10.3373 15.6249 10.8748 15.7187 11.3123 15.8958L17.8748 18.6353C18.2498 18.7916 18.9748 18.7708 19.3498 18.6041L24.7623 16.0208C25.1623 15.8333 25.5498 15.2708 25.5498 14.8958V4.76033C25.5498 4.10408 25.3248 3.6145 24.9123 3.427C24.5123 3.2395 23.8873 3.31242 23.2123 3.63533L20.2748 5.03117C19.3623 5.46867 17.9748 5.49992 17.0373 5.10408L10.4748 2.3645C10.0998 2.20825 9.3748 2.22908 8.9998 2.39575L3.5873 4.97908C3.1873 5.16658 2.7998 5.72908 2.7998 6.1145V16.2499C2.7998 16.9062 3.0248 17.3958 3.4248 17.5833C3.8248 17.7812 4.4498 17.6978 5.1373 17.3749L8.07481 15.9791C8.5623 15.7395 9.18731 15.6249 9.7998 15.6249Z" fill="#CED2DA"/>
</svg>

  );
};

export default Map;
