
import React from 'react';

const Group15 = (props)=> {
  return (
    <svg width="25" height="23" viewBox="0 0 25 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="11.4606" height="10.028" rx="1" fill="#CED2DA"/>
<rect x="13.5391" width="11.4606" height="10.028" rx="1" fill="#CED2DA"/>
<rect y="12.1069" width="11.4606" height="10.028" rx="1" fill="#CED2DA"/>
<rect x="13.5391" y="12.1069" width="11.4606" height="10.028" rx="1" fill="#CED2DA"/>
</svg>

  );
};

export default Group15;
