
import React from 'react';

const Group17 = (props)=> {
  return (
    <svg width="27" height="22" viewBox="0 0 27 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="11" height="5.5" rx="1" fill="#CED2DA"/>
<rect x="6.875" width="19.25" height="5.5" rx="1" fill="#CED2DA"/>
<rect y="8.25" width="11" height="5.5" rx="1" fill="#CED2DA"/>
<rect y="16.5" width="11" height="5.5" rx="1" fill="#CED2DA"/>
<rect x="6.875" y="8.25" width="19.25" height="5.5" rx="1" fill="#CED2DA"/>
<rect x="6.875" y="16.5" width="19.25" height="5.5" rx="1" fill="#CED2DA"/>
</svg>

  );
};

export default Group17;
