import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import styled from "styled-components";

import Header from "./Components/Header";
import Live from "./Pages/LivePage";
import Search from "./Pages/SearchPage";
import Insights from "./Pages/InsightsPage";
import Devices from "./Pages/DevicesPage";
import Events from "./Pages/EventsPage";

const ContentWrapper = styled.div`
  margin-top: 100px;
  padding: 0 40px;
`;

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <ContentWrapper>
          <Routes>
            <Route path="live" element={<Live />} />
            <Route path="search" element={<Search />} />
            <Route path="insights" element={<Insights />} />
            <Route path="devices" element={<Devices />} />
            <Route path="events" element={<Events />} />
          </Routes>
        </ContentWrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
