import React, { useState, useRef } from "react";
import styled from "styled-components";
import { SelectPicker, Dropdown } from "rsuite";
import { Modal, Input, Radio, Button } from "antd";
import {
  AiOutlinePlusCircle,
  AiOutlineLeftSquare,
  AiOutlineRightSquare,
  AiOutlineDown,
} from "react-icons/ai";
import ReactPlayer from "react-player";

import CustomDiv from "./../../Components/CustomDiv";
import CameraSelect from "./CameraSelect";
import Toggle from "./Toggle";
import data from "./Data";
import SmallIcon from "./Icons/Small";
import MidIcon from "./Icons/Mid";
import LargeIcon from "./Icons/Large";
import MapIcon from "./Icons/Map";
import Arrow from "./Icons/Arrow";
import Ellipse from "./Icons/Ellipse";
import Search from "./Icons/Search";
import Archieve from "./Icons/Archieve";
import GoogleMap from "./Icons/GoogleMap.svg";
import BuildingMap from "./Icons/BuildingMap.svg";
// import Rectangle from "./Icons/Rectangle";

const Header = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
`;

const Content = styled.div`
  display: ${({ mapView }) => (mapView ? "none" : "flex")};
  align-items: center;
`;

const ConfirmBtn = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: right !important;
  color: #ff6600;
  padding: 18px 16px 8px 16px;
  cursor: pointer;
`;

const Extra = styled.div`
  display: flex;
  justify-content: end;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #e4e7ec;
`;

const CameraInfoT = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #414e62;
  margin-left: 12px;
`;

const WallBtn = (props, ref) => {
  return (
    <Button
      {...props}
      ref={ref}
      style={{ backgroundColor: "#97A1AF", color: "white" }}
    >
      Custom Wall
    </Button>
  );
};

const ModalContent = styled.div`
  padding: 55px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  gap: 11px;
`;

const ModalHeaderText1 = styled.div`
  letter-spacing: 0.025em;
  font-size: 24px;
  font-weight: 600;
  color: #414e62;
`;

const ModalHeaderText2 = styled.div`
  letter-spacing: 0.025em;
  color: #97a1af;
  font-size: 16px;
  font-weight: 400;
`;

const ModalDescription = styled.div`
  color: #637083;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;
  font-size: 16px;
  margin-bottom: 10px;
`;

const InputX = styled(Input)`
  border: 1px solid #97a1af;
  border-radius: 4px;
  padding: 14px;
  width: 250px;
`;

const WallSaveBtn = styled(Button)`
  background-color: #ff6600;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 16px 10px 16px;
  width: 150px;
  height: 45px;
  color: white;
`;

const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${({ video, viewMethod }) =>
      video === 1 ? 4 / viewMethod : 6 / viewMethod},
    1fr
  );

  row-gap: 30px;

  padding: 10px 13.5px 10px 0px;
  /* height: fit-content; */
`;

const ImageW = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

const CameraP = styled.div`
  display: flex;
  align-items: center;
  color: black;
  font-size: 16px;
  font-weight: 700;
`;

const RoomP = styled.div`
  display: flex;
  align-items: center;
  color: #828282;
  font-size: 20px;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 8px;
`;

const MyScroll = styled.div`
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 0px;
`;

const ScrollBtn = styled.button`
  border-radius: 50%;
  border: 1.5px solid #828282;
  background-color: white;
  cursor: pointer;
  color: gray;
`;

const Hr = styled.hr`
  background-color: black;
  width: 1px;
  margin: 8px;
  height: 520px;
`;

const VideoWrapper = styled.div`
  display: ${({ video }) => (video === 1 ? "flex" : "none")};
  position: absolute;
  left: 50%;
  flex-direction: column;
  row-gap: 8px;
  margin-left: 54px;
`;

const CameraInfo = styled.div`
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  color: black;
  display: flex;
  margin-bottom: 12px;
`;

const SearchBtn = styled.button`
  padding: 10px;
  width: fit-content;
  background-color: #828282;
  border: 1px solid #828282;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ClipBtn = styled.button`
  width: 113px;
  border: 1px solid #212529;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 6px;
  font-weight: 500;
  letter-spacing: -0.05em;
  background-color: #212529;
  color: white;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const MySpan = styled.span`
  margin: 0 12px;
`;

const MyArrow = styled.div`
  cursor: pointer;
`;
const ImageGroup = styled.div`
  height: 600px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;
const ImageContainer = styled.div`
  position: relative;
  width: ${({ video }) => `${100 / (video + 1)}%`};
`;

const Rectangle = styled.div`
  display: flex;
  align-items: center;
  background-color: #d9d9d9;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border: ${({ videoId }) => (!videoId ? "none" : "3px solid #FE6601")};
`;

const MapSection = styled.div`
  padding-right: 10px;
`;
const Location = styled.div`
  margin-top: 41px;
  span1 {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0.025em;
  }
  li {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.025em;
    color: #4f4f4f;
    padding: 8px 0;
  }
`;
const FloorDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  opacity: 0.65;
  border: 1px solid #828282;
  border-radius: 4px;
  padding: 6px;
  width: fit-content
  margin: 25px 0;
  span {
    color: black;
    font-weight: 600;
  }
  svg {
    cursor: pointer;
  }
`;

const MapChangeBtn = styled(Button)`
  border: 1px solid #94a3b8;
  border-radius: 8px;
  background: #f2f4f7;
  color: #94a3b8;
  font-size: 14px;
  font-weight: 400;
`;

const Main = () => {
  const cameraChange = (value) => {
    console.log("Value: ", value);
  };
  const [cameraType, setCameraType] = useState(false);
  const [wallModal, setWallModal] = useState(false);

  const [video, setVideo] = useState(0);
  const [videoId, setVideoId] = useState(null);
  const [cameraId, setCamera] = useState("");
  const [roomId, setRoom] = useState("");
  const [viewMethod, setView] = useState(1);
  const [mapView, setMapView] = useState(0);
  const [mapState, setMap] = useState(0);
  console.timeLog("mapView, ", mapView);
  const scrollRef = useRef(null);
  const handleScrollUp = () => {
    scrollRef.current.scrollTop -= 20;
  };
  const handleScrollDown = () => {
    scrollRef.current.scrollTop += 20;
  };
  return (
    <>
      <Header>
        <CustomDiv>
          <CameraSelect
            defaultExpandAll
            placeholder="Cameras"
            data={data.camera}
            onChange={cameraChange}
            renderExtraFooter={() => (
              <Extra>
                <ConfirmBtn>Confirm</ConfirmBtn>
              </Extra>
            )}
          />
          <CustomDiv>
            <Toggle
              size="lg"
              onChange={() => setCameraType(!cameraType)}
              bgCol={cameraType}
            />
            <CameraInfoT>Include offline Cameras</CameraInfoT>
          </CustomDiv>
        </CustomDiv>
        <CustomDiv>
          <CustomDiv style={{ gap: 6 }}>
            <IconButton
              view={viewMethod === 1}
              onClick={() => {
                setView(1);
                setMapView(0);
              }}
            >
              <SmallIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setView(2);
                setMapView(0);
              }}
            >
              <MidIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setView(3);
                setMapView(0);
              }}
            >
              <LargeIcon />
            </IconButton>
            <IconButton onClick={() => setMapView(1)}>
              <MapIcon />
            </IconButton>
          </CustomDiv>
          <Dropdown renderToggle={WallBtn} style={{ marginLeft: 25 }}>
            <Dropdown.Item>Tables</Dropdown.Item>
            <Dropdown.Item>Kitchen</Dropdown.Item>
            <Dropdown.Item>
              <CustomDiv onClick={() => setWallModal(true)}>
                <AiOutlinePlusCircle />
                <div>&nbsp;Create New Wall</div>
              </CustomDiv>
            </Dropdown.Item>
          </Dropdown>
        </CustomDiv>
        <div>
          <SelectPicker
            label="Sort by:"
            data={data.sortKey}
            style={{ width: 150 }}
          />
        </div>
      </Header>
      <Content mapView={mapView}>
        <ImageContainer video={video}>
          <ImageGroup>
            <ImageWrapper video={video} viewMethod={viewMethod} ref={scrollRef}>
              {data.videoImage.map((Mock, id) => (
                <ImageW key={`mock-video-${id}`}>
                  <Rectangle
                    width={`${10 * viewMethod}vw`}
                    height={`${8 * viewMethod}vw`}
                    videoId={videoId === id}
                    onClick={() => {
                      setVideo(1);
                      setCamera(Mock.cameraId);
                      setRoom(Mock.roomId);
                      setVideoId(id);
                    }}
                  ></Rectangle>
                  <RoomP>{Mock.roomId}</RoomP>
                  <CameraP>{Mock.cameraId}</CameraP>
                </ImageW>
              ))}
            </ImageWrapper>
          </ImageGroup>
          <MyScroll>
            <ScrollBtn onClick={handleScrollUp}>&and;</ScrollBtn>
            <Hr />
            <ScrollBtn onClick={handleScrollDown}>&or;</ScrollBtn>
          </MyScroll>
        </ImageContainer>
        <VideoWrapper video={video}>
          <CameraInfo>
            <MyArrow
              onClick={() => {
                setVideo(0);
                setVideoId("");
              }}
            >
              <Arrow />
            </MyArrow>
            <MySpan>{roomId + " - " + cameraId}</MySpan>
            <Ellipse />
          </CameraInfo>
          <SearchBtn>
            <Search />
            &nbsp;&nbsp;&nbsp;
            <span>Search Recording</span>
          </SearchBtn>
          <div>
            <ReactPlayer
              url="../../Media/test.mp4"
              controls={true}
              width="90%"
            />
          </div>
          <ClipBtn>
            <Archieve />
            &nbsp;&nbsp;&nbsp;
            <span>Make a Clip</span>
          </ClipBtn>
        </VideoWrapper>
      </Content>
      <Content mapView={!mapView} style={{ alignItems: "flex-start" }}>
        <MapSection style={{ width: 1100 }}>
          <FloorDiv>
            <AiOutlineLeftSquare />
            <span>Floor 1</span>
            <AiOutlineRightSquare />
          </FloorDiv>
          {mapState ? <img src={BuildingMap} /> : <img src={GoogleMap} />}
        </MapSection>
        <Location>
          <div style={{ marginBottom: 24 }}>
            <span1>Your locations:</span1>
          </div>
          <div>
            <li>Oakland Zoo - Oakland, CA</li>
            <li>South St. 45 - San Francisco, CA</li>
            <li>Dolores St.5 - San Francisco, CA</li>
          </div>
          <MapChangeBtn
            onClick={() => setMap(!mapState)}
            style={{ marginTop: 60 }}
          >
            {!mapState ? "Building Map View" : "Google Map View"}
          </MapChangeBtn>
        </Location>
      </Content>
      <Modal
        open={wallModal}
        onOk={() => {
          setWallModal(false);
        }}
        onCancel={() => setWallModal(false)}
        centered
        footer={() => {
          return null;
        }}
        width={850}
      >
        <ModalContent>
          <ModalHeader>
            <ModalHeaderText1>Create a Custom Wall View</ModalHeaderText1>
            <ModalHeaderText2>
              You can save a custom wall view including your choice of Cameras.
            </ModalHeaderText2>
          </ModalHeader>
          <div>
            <ModalDescription>
              Choose Cameras to include in Wall
            </ModalDescription>
            <CameraSelect
              defaultExpandAll
              placeholder="Cameras"
              data={data.camera}
              onChange={cameraChange}
              renderExtraFooter={() => (
                <Extra>
                  <ConfirmBtn>Confirm</ConfirmBtn>
                </Extra>
              )}
            />
          </div>
          <div>
            <ModalDescription>Choose a name for your Wall</ModalDescription>
            <InputX placeholder="Name your Wall" />
          </div>
          <div>
            <Dropdown title="Grid View">
              <Dropdown.Item>Grid View</Dropdown.Item>
              <Dropdown.Item>Column View</Dropdown.Item>
            </Dropdown>
          </div>
          <div>
            <ModalDescription>Camera preferences</ModalDescription>
            <Radio>Auto-Rotated in full view</Radio>
          </div>
        </ModalContent>
        <CustomDiv style={{ justifyContent: "center" }}>
          <WallSaveBtn>Save Wall</WallSaveBtn>
        </CustomDiv>
      </Modal>
    </>
  );
};

export default Main;
