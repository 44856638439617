import React from "react";
import Footer from "./RecentSearch";

const Search = () => {
  return (
    <div>
      <Footer />
    </div>
  );
};

export default Search;
